<template>
  <div class="registration">
    <Registration />
  </div>
</template>

<script>
// @ is an alias to /src
import Registration from "../components/registration/index.vue";
export default {
  components: {
    Registration,
  },
};
</script>
