
<template>
  <div class="ytd">
    <ytd />
  </div>
</template>

<script>
// @ is an alias to /src
import YTD from "../components/ytd/index.vue";
export default {
  components: {
    ytd: YTD,
  },
};
</script>
