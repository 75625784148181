<template>
  <div class="sample-file-upload">
    <PageNotFound />
  </div>
</template>

<script>
// @ is an alias to /src
import PageNotFound from "../components/PageNotFound.vue";
export default {
  components: {
    PageNotFound,
  },
};
</script>
